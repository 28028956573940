import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import DefaultLayout from "../components/default-page-layout"
import ImgSharpInline from "../components/ImgSharpInline.js"
import rehypeReact from "rehype-react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const renderAst = new rehypeReact({
  Fragment: React.Fragment,
  createElement: React.createElement,
  components: {
    "img-sharp-inline": ImgSharpInline,
  },
}).Compiler

export default function staticPageTemplate({ data }) {
  //data.markdownRemark holds your post data
  return (
    <>
      <DefaultLayout>
        <GatsbySeo
          title={data.ghostPage.meta_title}
          description={data.ghostPage.meta_description}/>
        <article className="prose mx-auto py-4">
          <h1>{data.ghostPage.title}</h1>
          {renderAst(data.ghostPage.childHtmlRehype.htmlAst)}
        </article>
      </DefaultLayout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      title
      meta_title
      meta_description
      childHtmlRehype {
        htmlAst
      }
    }
  }
`
